/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 3840px) {
  .home-page {
    padding: 126px 500px;

    .ctn-homepage {
      .col-right {
        .ctn-please-join {
          margin: 0 0 75px 0;
          .please-join {
            width: 100%;
          }
        }
        .ctn-qr-code {
          width: 1200px;
          padding: 120px;
        }
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
  .home-page {
    padding: 126px 30px;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .home-page {
    .ctn-homepage {
      .col-left {
        .text {
          div {
          }
        }
      }
      .col-right {
        .ctn-please-join {
        }
        .ctn-qr-code {
          width: 400px;
        }
      }
    }
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .home-page {
    .ctn-homepage {
      .col-left {
        .text {
          div {
          }
        }
      }
      .col-right {
        .ctn-please-join {
        }
        .ctn-qr-code {
          width: 360px;
        }
      }
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .home-page {
    padding: 0;
    height: auto;
    flex: 1;
    .ctn-homepage {
      flex-direction: column;
      align-items: unset;
      justify-content: unset;
      height: auto;

      .col-left {
        max-width: unset;

        .text {
          margin-top: 15px;
          margin-bottom: 15px;
          display: flex;
          flex-wrap: wrap;

          div {
            margin-right: 3%;
          }
        }
      }
      .col-right {
        margin-bottom: 25px;
        display: block;
        .ctn-please-join {
          margin: 0 0 25px 0;
          justify-content: start;
        }
        .ctn-qr-code {
          max-width: 300px;
          margin: auto;
        }
      }
    }
  }
}

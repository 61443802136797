.qr-code-page {
  display: flex;
  justify-content: center;
  flex: 1;
  background: #151515;

  &.gc-page {
    background: none;

    .ctn-heropage {
      .col-left {
        opacity: 0;
        transition: opacity 0.8s;
        margin-right: 0;
      }
      .col-right {
        .claim-gift {
          margin-bottom: 5px;
        }
        .ctn-steps {
          position: relative;
          width: 500px;
          color: #b7b7bb;
          .step {
            margin-bottom: 20px;
          }
          .ctn-app-store {
            display: flex;
            // align-items: center;
            position: absolute;
            right: 0;
            top: 0;

            a{
              flex: 1;
            }
            .qr-code {
              max-width: 40px;
              margin-left: 5px;

              svg {
                width: 100%;
                height: 100%;
                border-radius: 6px;
              }
            }
          }
        }
        .ctn-qr-code {
          max-width: 260px;
          padding: 20px;
          margin-left: 40px;
        }
        .dynamic-qrcode {
          max-height: 220px;
          canvas {
            border-radius: 16px;
          }
          &.small {
            max-height: 40px;
            canvas {
              width: 40px;
              height: 40px;
              border-radius: 6px;
            }
          }
        }
      }
    }
  }

  .ctn-heropage {
    display: flex;
    align-items: center;
    &.page-00 {
      .col-left {
        opacity: 1;
        .text {
          div {
            opacity: 0;
            transition: opacity 0.8s;
          }
        }
      }
      .col-right {
        min-width: 430px;
        .ctn-please-join {
          text-align: center;
          justify-content: center;
        }
        .ctn-join-the-waitlist {
          text-align: center;
          margin-top: 40px;
        }
      }
    }
    &.page-01 {
      .col-left {
        opacity: 1;
        .text {
          div {
            opacity: 0;
            transition: opacity 0.8s;
          }
        }
      }
    }
    .col-left {
      min-width: 589px;
      opacity: 0;
      transition: opacity 0.8s;
      margin-right: 80px;
      .name-hero {
        margin-bottom: 20px;
      }
      .image-hero {
        img {
          max-width: 100%;
          max-height: 100%;
          display: block; /* remove extra space below image */
        }
      }
      .desc-hero {
        text-align: center;
        margin-top: 20px;
        svg {
          width: 100%;
        }
      }
    }
    .col-right {
      opacity: 0;
      transition: opacity 0.8s;
      margin-left: 80px;
      min-width: 300px;
      .ctn-please-join {
        margin-bottom: 40px;
        display: flex;
        align-items: center;

        .please-join {
        }
        .apple-logo {
          margin-right: 15px;
          height: 80px;
        }
        a {
          text-decoration: none;
        }
        svg {
          width: 100%;
          height: 100%;
          max-width: 80px;
        }
      }
      .ctn-qr-code {
        padding: 40px;
        border-radius: 30px;
        background: gray;
        background: linear-gradient(130deg, #ffffff80, #000000);
        max-width: 430px;
        max-height: 430px;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

#root {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

html,
body {
  height: 100%;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #e5e5ea !important;
}

a:hover,
button:hover {
  cursor: pointer;
  opacity: 0.8;
}

ul {
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0;
}

button,
input,
textarea {
  outline: none;
}

svg {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  margin: auto;
  width: 100%;
  padding: 0 8rem;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 1920px) and (max-width: 2559px) {
  .container {
    padding: 0 7rem;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1919px) {
  .container {
    padding: 0 6rem;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1599px) {
  .container {
    padding: 0 5rem;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1365px) {
  .container {
    padding: 0 4rem;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1279px) {
  .container {
    padding: 0 3rem;
  }
}

@media only screen and (min-width: 769px) and (max-width: 991px) {
  .container {
    padding: 0 2rem;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 0 1rem;
  }
}

.text-dark-hd {
  color: black !important;
}

.ant-collapse {
  border: 1px solid #000 !important;
  background-color: #000;
}

.ant-collapse > .ant-collapse-item {
  border: 1px solid #000 !important;
  background-color: #000;
  border-bottom: 1px solid #979797 !important;
}

.ant-collapse > .ant-collapse-item:last-child {
  border: 1px solid #000 !important;
}

.ant-collapse-header {
  position: relative;
  min-height: 72px !important;
  padding: 16px 0 !important;
  /* font-family: "Montserrat-Bold" !important; */
  font-weight: 700;
  font-size: 16px !important;
  color: #fff !important;
  display: flex;
  align-items: center !important;
}

.ant-collapse-header > p {
  max-width: 772px;
  padding-right: 72px;
  margin-bottom: 0 !important;
}

.ant-collapse-header > div {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translate(0, -50%);
  background-color: #333333;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-collapse-arrow {
  margin-right: 0 !important;
}

.ant-collapse-content {
  color: #ccc;
  border: 1px solid #000 !important;
  background-color: #000 !important;
  max-width: 800px;
}

.ant-collapse-content p {
  margin-bottom: 28px !important;
  padding-right: 72px;
}

.ant-collapse-content ul {
  list-style-type: disc !important;
  padding-left: 20px !important;
}

.ant-collapse-content-box {
  padding: 0 !important;
}

.ant-collapse-item-active .ant-collapse-arrow {
  transform: rotate(45deg) !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  transition: transform 0.24s !important;
}


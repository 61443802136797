.home-page {
  // height: calc(100vh - 131px);
  // background-color: #1a1919;
  flex: 1;
  border-radius: 20px;
  padding: 126px 261px;
  &.hidden {
    visibility: hidden;
  }
  &.visible {
    visibility: visible;
  }

  .ctn-homepage {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    .col-left {
      .text {
        margin-top: 63px;
        min-height: 80px;

        div {
          opacity: 0;
          transition: opacity 0.8s;
        }
      }
    }

    .col-right {
      opacity: 0;
      transition: opacity 0.8s;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .ctn-please-join {
        margin: 0 0 20px;
        display: flex;
        width: 100%;

        .apple-logo {
          margin-right: 15px;
        }
        .please-join {
          width: 100%;
        }
        svg {
          width: 100%;
          height: 100%;
          max-width: 80px;
        }
      }

      .ctn-qr-code {
        padding: 40px;
        border-radius: 30px;
        background: gray;
        background: linear-gradient(130deg, #ffffff80, #000000);
        width: 400px;
        height: auto;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.box-invite-code {
  margin-top: 24px;
  background-image: linear-gradient(to right, #8b4492, #979797);
  padding: 2px;
  border-radius: 12px;
  .btn-invite-code {
    width: 100%;
    height: 100%;
    background-color: #000;
    border-radius: inherit;
    .icon-copy {
      opacity: 0;
    }
    &:hover {
      .icon-copy {
        opacity: 0.7;
        transition: all 0.2s;
      }
    }
  }
}

.harmony {
  margin: 16px auto;

  .box-invite-code {
    max-width: 375px;
    margin: 24px auto 16px auto;
  }
}

@media only screen and (max-width: 980px) {
  // .harmony {
  //   .box-invite-code {
  //     margin: 24px auto 16px auto;
  //     max-width: 340px;
  //     border-radius: 20px;
  //   }
  // }
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 500px) {
  .harmony {
    // max-width: 340px;
    // .box-invite-code {
    //   margin: 24px 12px 16px 12px;
    //   border-radius: 16px;
    //   max-width: 300px;
    // }
  }
}

.referral-page {
  .custom-headers {
  }
}

@media only screen and (max-width: 600px) and (min-width: 500px) {
  .referral-page {
    .custom-headers {
      .logo {
        a {
          font-size: 20px;
        }
      }
      .btn-waitlist {
        svg {
          width: 100px;
        }
        &.join {
          margin-left: 12px;
        }
      }
    }

    // .box-invite-code{

    // }
  }
}

/* Extra small devices (phones, 600px and down) */
.gc-top {
  .btn-waitlist {
    background: none;
    border: none;
  }
}

@media only screen and (max-width: 499px) {
  header {
    margin: 0 8px;
    min-height: 65px;
    .logo {
      a {
        font-size: 18px;
      }
    }
    .gc-top {
      .btn-waitlist {
        background: none;
        border: none;
      }
      .icons-container {
        .icon {
          width: 1rem;
          height: 1rem;
          svg {
            width: 1rem;
            height: 1rem;
          }
        }
      }
    }

    .ctn-btn-waitlist {
      .btn-waitlist {
        padding: 0px;

        &.join {
          margin-left: 16px;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) and (min-width: 601px) {
  header {
    margin: 0 8px;
    min-height: 65px;
    .logo {
      a {
        font-size: 28px;
      }
    }
    .gc-top {
      .icons-container {
        .icon {
          width: 1rem;
          height: 1rem;
          svg {
            width: 1rem;
            height: 1rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) and (min-width: 500px) {
  header {
    margin: 0 8px;
    min-height: 65px;
    .logo {
      a {
        font-size: 25px;
      }
    }
    .gc-top {
      .icons-container {
        .icon {
          width: 1rem;
          height: 1rem;
          svg {
            width: 1rem;
            height: 1rem;
          }
        }
      }
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
@media only screen and (min-width: 3840px) {
  header {
    height: 195px;
    .logo {
      a {
        font-size: 105px;
      }
    }
  }
}

@media only screen and (max-width: 365px) {
  header {
    .logo {
      a {
        font-size: 1rem;
      }
    }
  }
}

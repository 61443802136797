footer {
  height: $height-footer;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  margin: 0 27px;

  &.new-footer {
    height: 100px;
    z-index: 99;

    .icons-container {
      display: none;
    }
  }

  &.is-mobile {
    display: none;
  }

  .icons-container {
    display: flex;
    align-items: center;
    gap: 16px;

    .icon {
      // width: 22px;
      height: 22px;
      // margin: 0 9.5px;
      padding-top: 6px;
      &.twitter {
        svg {
          width: 18px;
          height: 18px;
          margin-top: 2px;
        }
      }
      &:nth-child(1) {
        // margin-left: 0px;
      }

      svg {
        width: 22px;
        height: 22px;
        // margin: 0 7.5px;
        cursor: pointer;

        &.hovered {
          transform: translateY(-8px);
          transition: transform 300ms;
        }
        &.normal {
          transform: translateY(0px);
          transition: transform 300ms;
        }
      }
    }
  }

  .made-by {
    display: flex;
    user-select: none;
    align-items: center;
  }
  .harmony {
    color: #d8d8d8;
    // margin-left: 4px;
    div {
      line-height: normal;
    }
  }
}

@media only screen and (min-width: 3840px) {
  footer {
    height: 194px;
    .icons-container {
      .icon {
        &.twitter {
          svg {
            margin-left: 32px !important;
          }
        }
        svg {
          width: 72px;
          height: 60px;
          margin: 0 22px;
        }
      }
    }
    .made-by {
      svg {
        width: 64px;
        height: 64px;
      }
    }
  }
}

.new-footer-2 {
  transform: translate(-50%, -50%) scale(1);
  position: absolute;
  left: 50%;
  top: 75%;
}

@media only screen and (max-width: 767px) {
  .harmony {
    // margin-left: 0px !important;
  }
  .new-footer-2 {
    transform: translate(-50%, -50%) scale(1);
    top: 80%;
  }
}

@media only screen and (max-width: 1200px) and (orientation: landscape) {
  .new-footer-2 {
    transform: translate(-50%, -50%) scale(1);
  }
}

@media only screen and (max-width: 767px) and (orientation: landscape) {
  .new-footer-2 {
    transform: translate(-50%, -50%) scale(1);
  }
}

.tutorial {
  user-select: none;
}

.disable-long-press {
  -webkit-touch-callout: none;
  user-select: none;
}

.cursor-pointer {
  cursor: pointer;
}

.hidden-scroll-bar {
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.iphone {
  width: 100%;
  height: auto;
  -webkit-touch-callout: none;
}

.page-1 {
  display: flex;
  align-items: "center";
  justify-content: "center";
}

.background-blur {
  background: rgba(149, 149, 149, 0.25);
  backdrop-filter: blur(50px);
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  position: absolute;
  z-index: 2;
  border-radius: 46px;
}

.content {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 15px;
  scale: 0.8;
  z-index: 3;
}

.title-intro {
  font-weight: 500;
  text-align: center;
}

.sub-title-intro {
  font-size: 55px;
  font-weight: bold;
  text-align: center;
}

.content-intro {
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 35px;
}

.left-intro {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.right-intro {
  .intro-image {
    max-width: 600px;
    width: 100%;
  }
}

.contact-button {
  padding: 8px 30px;
  border-radius: 16px !important;
}

.contact-link {
  font-weight: 600;
  color: #0a84ff !important;
}

.scroll-down {
  scale: 0.8;
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  pointer-events: all;
}

.black-button {
  outline: none;
  border: none;
  background: rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 0 1px 0 rgba(255, 255, 255, 0.3),
    inset 1px 1px 4px 0 rgba(0, 0, 0, 0.08);
  border-radius: 15px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;
  padding-bottom: 16px;
  font-family: "SF-Pro";
  color: #fff;
  flex-wrap: wrap;
  height: 70px;

  .share-text {
    color: rgba($color: #fff, $alpha: 0.5);
    font-weight: 500;
  }

  .telegram-contact {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    padding: 5px 9px;
    background: rgba(120, 120, 128, 0.2);
    border-radius: 16px;
    font-weight: bold;

    .telegram-icon {
      width: 22px;
      height: auto;
      aspect-ratio: 1;
    }

    .telegram-text {
      color: rgba($color: #fff, $alpha: 0.7);
    }
  }
}

.header {
  padding: 20px 30px 4px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .logo {
    height: 28px;
    img {
      height: 100%;
      object-fit: contain;
    }
  }

  .content-step {
    flex: 1;
  }
}

.group-button {
  display: flex;
  gap: 8px;
  .button-link {
    width: 43px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 22px;
      height: 36px;
    }
  }
}

.background {
  position: fixed;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.intro-section {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.window-platter {
  background: rgba(149, 149, 149, 0.25);
  border-radius: 46px;
  backdrop-filter: blur(50px);
  width: 100%;
  height: 100%;
  max-height: 750px;
  max-width: 1600px;
  padding: 30px 50px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  .content-step {
    flex: 1;
    overflow: auto;
  }
}

.fade-in {
  animation: fadeInAnimation ease 0.8s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.content-tutorial {
  .header-content {
    display: flex;
    justify-content: space-between;
    min-height: 60px;

    .guide-button {
      display: flex;
      column-gap: 6px;
      justify-content: center;
      align-items: center;
      height: 44px;
      padding: 0px 16px;
      background-color: rgba($color: #000000, $alpha: 0.15);
      border-radius: 22px;
      box-shadow: inset 0 0 10px rgba($color: #000000, $alpha: 0.3);

      span {
        color: white;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}

.tutorial,
.intro {
  width: 100%;
  height: 100%;
  font-family: "SF-Pro";
  position: relative;

  .image-container {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
    min-height: 300px;
  }

  .skeleton-loader {
    transition: 0.2s all linear;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.06) 25%,
      rgba(0, 0, 0, 0.15) 37%,
      rgba(0, 0, 0, 0.06) 63%
    );
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    z-index: 1;
    min-height: 400px;
  }

  @keyframes loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }

  .contact-button {
    padding: 8px 30px;
    border-radius: 16px !important;
  }

  .contact-link {
    font-weight: 600;
    color: #0a84ff !important;
  }

  .content-tutorial {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    height: 100%;
    .box-content {
      flex: 1;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      .linear-logo {
        height: 37px;
      }
    }
  }

  .button {
    -webkit-touch-callout: none;
    background: #ffffff14;
    border-radius: 22px;
    color: #fff;
    display: flex;
    justify-content: center;
    padding: 5px 50px;
    width: max-content;
    transition: 0.2s all linear;
    &:hover {
      background-color: #00000014;
    }
  }

  .start-step {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-family: "SF-Pro";
    height: 100%;

    .left {
      width: 40%;
    }

    .right {
      width: 20%;
      display: flex;
      align-items: center;
    }

    .title {
      font-weight: bold;
      font-size: 28px;
      color: #ffffffb3;
    }

    .sub-title {
      font-weight: bold;
      font-size: 55px;
      margin-bottom: 14px;
    }

    .button {
      font-size: 22px;
      width: max-content;
      font-weight: bold;
    }
  }

  .tabs {
    background: rgba(0, 0, 0, 0.18);
    box-shadow: inset 0 0 1px 0 rgba(255, 255, 255, 0.3),
      inset 1px 1px 4px 0 rgba(0, 0, 0, 0.08);
    border-radius: 22px;
    display: flex;
    gap: 10px;
    padding: 5px 10px;
    width: max-content;
    margin-bottom: 14px;

    .tab-item {
      border: none;
      outline: none;
      padding: 5px 20px;
      border-radius: 22px;
      font-weight: 500;
      cursor: pointer;
      transition: 0.2s all linear;
      background: transparent;
      font-family: "SF-Pro";
      font-weight: 500;

      &.--active {
        background: rgba(0, 0, 0, 0.18);
      }
    }
  }

  .timeless-tab,
  .intract-tab,
  .linea-tab,
  .claim-crypto-tab {
    display: flex;
    justify-content: space-evenly;
    font-family: "SF-Pro";
    height: 100%;
    .left {
      width: 40%;
      height: 100%;
      overflow: auto;
      display: flex;
      flex-direction: column;
    }
    .right {
      width: 20%;
      display: flex;
      align-items: center;
    }
  }
  .video-group {
    position: relative;
    overflow: hidden;
    .video-step-2 {
      position: absolute;
      border-radius: 40px;
      object-fit: cover;
      z-index: 2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% - 25px);
      height: calc(100% - 20px);
      overflow: hidden;
    }
    .button-play-pause {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;
      transition: 0.2s all linear;
      border: none;
      outline: none;
      border-radius: 50%;
      background: none;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: max-content;
      height: auto;
      aspect-ratio: 1;

      &.--visible {
        opacity: 1;
      }
      &.--disable {
        opacity: 0;
      }
    }

    .play-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .group-button-download {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    .download-apple {
      width: 120px;
      min-height: 40px;
    }

    .download-google {
      width: 135px;
      min-height: 40px;
    }
  }

  .steps {
    transition: 0.2s all linear;
    flex: 1;
    overflow-y: scroll;
    .timeless-step-3 {
      margin-top: 15px;
      display: flex;
      gap: 5px;
      font-weight: bold;
    }

    .step {
      padding: 10px;
      transition: 0.2s all linear;
      border-radius: 16px;
      overflow: hidden;
      &.--active {
        background: rgba(48, 48, 101, 0.12);
      }

      &:not(:last-child) {
        margin-bottom: 24px;
      }

      .social-button-container {
        display: flex;
        column-gap: 16px;
        margin-top: 24px;

        .social-button {
          background-color: rgba($color: #3c3c43, $alpha: 0.3);
          border: none;
          border-radius: 16px;
          padding: 6px 12px;
          font-size: 14px;
          font-weight: bold;
          display: flex;
          column-gap: 8px;
          align-items: center;
        }
      }

      .timeless-step-4-next-button {
        margin-top: 24px;
        border: none;
        height: 38px;
        width: 137px;
        background-color: rgba($color: #3c3c43, $alpha: 0.3);
        border-radius: 16px;
        color: white;
        font-size: 14px;
        font-weight: bold;
      }

      .go-to-layer3-button {
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 180px;
        background-color: rgba($color: #3c3c43, $alpha: 0.3);
        border-radius: 16px;
        color: white;
        font-size: 14px;
        font-weight: bold;
      }
    }
    .step-title {
      display: flex;
      gap: 15px;
      font-weight: bold;

      .number-circle {
        font-size: 19px;
        background: rgba(255, 255, 255, 0.12);
        border-radius: 50%;
        width: 44px;
        min-width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .title {
        font-size: 20px;
        margin-top: 6px;
        width: 100%;
        word-break: break-word;
        color: rgba($color: #fff, $alpha: 0.8);
      }
    }

    .step-sub-title {
      font-family: "SF-Pro";
      font-size: 16px;
      font-weight: 400;
      color: rgba($color: #fff, $alpha: 0.8);

      .hyperlink {
        font-weight: bold;
        text-decoration: underline;
      }
    }

    .d-none {
      opacity: 0;
    }

    .step-content {
      transition: 0.25s all linear;
      overflow: hidden;
    }
  }

  .button-follow {
    background: rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    padding: 9px 20px 9px 17px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    width: max-content;
    transition: 0.2s all linear;
    .icon {
      width: 20px;
      min-width: 20px;
      height: 20px;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.12);
    }
  }

  .box-content-intro {
    flex: 1;
  }
}

@media screen and (max-width: 1380px) {
  .start-step {
    padding: 40px 0;
    .left {
      .title {
        font-size: 22px;
      }
      .sub-title {
        font-size: 44px;
      }
    }
    .button {
      font-size: 20px !important;
    }
  }
  .right {
    .video-group {
      .video-step-2 {
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        border-radius: 28px;
        object-fit: contain;
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .start-step {
    .left {
      .title {
        font-size: 20px;
      }
      .sub-title {
        font-size: 36px;
      }
    }
    .button {
      font-size: 18px !important;
    }
  }
  .right {
    .video-group {
      .video-step-2 {
        width: calc(100% - 18px);
        height: calc(100% - 15px);
        border-radius: 28px;
      }
    }
  }
}

@media screen and (max-width: 660px) {
  .content-step {
    overflow: auto !important;
  }
  .start-step {
    .left {
      width: auto !important;
      .title {
        font-size: 20px;
      }
      .sub-title {
        font-size: 36px;
      }
    }
    .right {
      width: auto !important;
    }
    .button {
      font-size: 18px !important;
    }
  }
  .timeless-tab,
  .intract-tab,
  .linea-tab,
  .claim-crypto-tab {
    .left {
      width: 100% !important;
      overflow: unset !important;
    }
    .right {
      width: 100% !important;
      min-height: 300px !important;
    }
  }
}

//tablet
@media screen and (max-width: 991px) {
  .scroll-down {
    display: none;
  }

  .header {
    padding: 15px 20px 4px 20px !important;
  }
  .footer {
    height: 65px;
  }

  .sub-title-intro {
    font-size: 30px;
  }

  .content-intro {
    font-size: 16px;
  }

  .intro-image {
    max-width: 370px !important;
  }

  .iphone {
    width: 160px !important;
  }

  .content-tutorial {
    .box-content {
      padding-bottom: 30px !important;
    }
  }

  .scroll-down {
    scale: 0.7;
  }

  .linear-logo {
    height: 20px !important;
  }

  .guide-button {
    height: 30px !important;
    padding: 0px 8px !important;

    .icon {
      width: 20px;
    }

    span {
      font-size: 11px !important;
    }
  }

  .content-tutorial .header-content {
    min-height: 40px;
    align-items: center;
  }

  .tutorial,
  .intro {
    .box-content-intro {
      padding-bottom: 70px;
      .content {
        display: flex;
        flex-direction: column-reverse;
      }
    }

    .start-step {
      .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .title {
          font-size: 20px;
        }
        .sub-title {
          font-size: 30px;
        }
        .button {
          font-size: 16px;
        }
      }
      .right {
        width: auto !important;
      }
    }
    .window-platter {
      padding: 28px;
      border-radius: 30px;
    }

    .timeless-tab,
    .intract-tab,
    .linea-tab,
    .claim-crypto-tab {
      .left {
        width: 50%;
        .tabs {
          margin-left: auto;
          margin-right: auto;
        }
      }
      .right {
        width: 50%;
        min-width: 150px;
        height: 100%;
        display: flex;
        justify-content: center;
        .image-container {
          display: flex;
          justify-content: center;
        }
        .video-group {
          .video-step-2 {
            width: calc(100% - 14px);
            height: calc(100% - 10px);
            border-radius: 24px;
          }
        }
      }
      .steps {
        .step {
          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }
        .step-title {
          .number-circle {
            font-size: 16px;
            width: 28px;
            height: 28px;
            min-width: 28px;
          }
          .title {
            margin-top: 0px;
            font-size: 16px;
          }
          .step-sub-title {
            font-size: 14px;
          }
          .button-follow {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 625px) {
  .video-group {
    .video-step-2 {
      width: calc(100% - 16px) !important;
      height: calc(100% - 12px) !important;
      border-radius: 18px !important;
    }
  }
  .image-container {
    max-width: 150px !important;
    max-height: 296px;
  }
  .header {
    padding: 10px 15px 5px 15px !important;
  }
  .footer {
    height: 40px;
  }

  .page-1 {
    .content {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      .left-intro {
        width: 100%;
        .sub-title-intro {
          font-size: 30px;
        }
        .content-intro {
          font-size: 15px;
        }
      }
      .right-intro {
        width: 100%;
        max-width: 250px;
        .intro-image {
          max-width: 250px;
        }
      }
    }
  }
  .content-tutorial {
    .box-content {
      padding-bottom: 20px !important;
    }
  }
  .window-platter {
    padding: 20px 25px !important;
  }
  .iphone {
    width: 150px !important;
  }
  .timeless-tab,
  .intract-tab,
  .linea-tab,
  .start-step,
  .claim-crypto-tab {
    flex-direction: column-reverse;
    justify-content: flex-end !important;
    gap: 15px;
    .right {
      display: flex;
      justify-content: center;
    }
    .left {
      height: auto !important;
    }
  }
  .steps {
    .step {
      &:not(:last-child) {
        margin-bottom: 10px !important;
      }

      .social-button-container {
        .social-button {
          font-size: 10px !important;
          padding: 2px 12px !important;
        }

        .x-logo {
          width: 15px;
        }

        .telegram-logo {
          width: 15px;
        }
      }

      .timeless-step-4-next-button {
        height: 32px !important;
        width: 110px !important;
        font-size: 12px !important;
      }

      .go-to-layer3-button {
        height: 32px !important;
        width: 140px !important;
        font-size: 11px !important;
      }
    }
  }

  // .start-step {
  //   justify-content: space-between;
  //   flex-direction: column-reverse;
  //   gap: 15px;
  //   .left {
  //     width: 100% !important;
  //     align-items: center !important;
  //     flex: 0 !important;
  //     height: max-content;
  //     .title {
  //       text-align: center;
  //     }
  //     .sub-title {
  //       text-align: center;
  //       font-size: 20px !important;
  //     }
  //   }
  //   .right {
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     width: 50% !important;
  //   }
  // }
}

@media screen and (max-width: 470px) {
  .window-platter {
    border-radius: 20px !important;
  }
  .button,
  .button-follow {
    width: 100% !important;
  }
  .group-button-download {
    flex-direction: column;
  }
}

@media screen and (max-width: 380px) {
  .image-container {
    max-height: 296px !important;
  }
  .step-title {
    .number-circle {
      font-size: 12px !important;
    }
    .title {
      font-size: 15px !important;
      .step-content {
        font-size: 14px !important;
      }
    }
  }
  .tabs {
    font-size: 12px;
    .tab-item {
      padding: 5px 10px !important;
      width: 50% !important;
    }
  }

  .page-1 {
    .content {
      .left-intro {
        width: 100%;
        .sub-title-intro {
          font-size: 21px;
        }
        .content-intro {
          font-size: 15px;
        }
        .contact-button {
          width: 100%;
        }
      }
      .right-intro {
        width: 100%;
        max-width: 200px;
        .intro-image {
          max-width: 200px;
        }
      }
    }
  }

  .guide-button {
    height: 26px !important;
    padding: 0px 8px !important;

    .icon {
      width: 15px;
    }

    span {
      font-size: 8px !important;
    }
  }

  .timeless-step-3 {
    flex-direction: column;
  }
}

.claim-crypto-tab {
  display: flex;
  .left {
    padding-top: 40px;
    .title {
      font-size: 34px;
      font-family: "Montserrat-Bold";
      color: rgba($color: #fff, $alpha: 0.8);
      padding-left: 20px;
      padding-bottom: 15px;
    }
    .sub-title {
      font-family: "SF-Pro";
      font-size: 20px;
      font-weight: bold;
      color: rgba($color: #fff, $alpha: 0.8);
      padding-left: 20px;
      margin-bottom: 20px;
    }
  }

  .link {
    display: flex;
    flex-direction: column;
    background: rgba(153, 17, 17, 0.4);
    border-radius: 20px;
    padding: 15px 15px 13px 15px;
    .claim-text {
      font-family: "SF-Pro";
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      // &:hover {
      //   text-decoration: underline;
      // }
    }
  }

  @media screen and (max-width: 1039px) {
    .left {
      .title {
        font-size: 30px;
      }
      .sub-title {
        font-size: 18px;
      }
    }
  }

  @media screen and (max-width: 991px) {
    gap: 50px;
    .left {
      .title {
        font-size: 24px;
      }
      .sub-title {
        font-size: 16px;
      }
    }
  }
  @media screen and (max-width: 625px) {
    gap: 15px;
    .left {
      padding-top: 0;
    }
    .right {
      max-height: 300px;
    }
  }
}

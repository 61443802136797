/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  footer {
    margin: 0;
    .icons-container {
      .icon {
        // margin: 0;
        width: 18px;
        height: 18px;
        // margin: 0 7.5px;
        svg {
          width: 18px;
          height: 18px;
          // margin: 0;
        }
      }
    }
    .made-by {
      // align-items: flex-end;
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 900px) {
  footer {
    margin: 0;
    .icons-container {
      gap: 16px !important;
      display: flex !important;
      .icon {
        // margin: 0;
        width: 18px;
        height: 18px;
        // margin: 0 9.5px;
        svg {
          width: 18px;
          height: 18px;
          // margin: 0;
        }
      }
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  footer {
    // margin: 0;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

.ant-modal-root {
  .ant-zoom-enter {
    animation-duration: 1.1s;
    animation-fill-mode: forwards;
  }
  .ant-zoom-leave {
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
  }
  .ant-modal-content {
    background-color: #000;
  }
}

.ant-dropdown-menu {
  padding: 6px 0px 0px 0px;

  .ant-dropdown-menu-item {
    padding: 3px 12px;
  }
}

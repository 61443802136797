.App {
  padding: 0 27px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 3840px) {
  .App {
    padding: 0 27px;
  }
}

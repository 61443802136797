#youngthug-mobile,
#kygo-mobile,
#mendes-mobile,
#qrhome-mobile,
#join-dogfood-mobile,
#join-gc-mobile {
  opacity: 0;
  transition: opacity 0.8s;
}
.new-mobile-layout {
  flex: 1;
  .ctn-testflight {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    margin-top: 15px;

    &.hidden {
      visibility: hidden;
    }
    .please-join {
      user-select: none;
    }

    .apple-logo {
      width: 38px;
      height: 38px;
      margin-right: 15px;
      svg {
        width: 100%;
        height: auto;
      }
    }
  }
  .ctn-card {
    background-color: #151515;
    border-radius: 12px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100% - 66px);
    text-align: center;
    justify-content: space-between;

    &.gc-mobile {
      height: calc(100% - 31px);
      background-color: #000;
      //style for container video
      > div:nth-child(1) {
        // flex: 1;
        align-self: center;
      }
      .icons-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        margin-bottom: 30px;
        .icon {
          margin: 0 5px;
        }
      }
      .ctn-steps-mobile {
        text-align: left;
        align-self: flex-start;
        .claim-gift {
          margin-bottom: 10px;
        }
        .ctn-steps {
          .step {
            margin-bottom: 20px;
          }
        }
      }
    }
    .ctn-text-qr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      width: 100%;
      .text {
        text-align: left;
        div {
          opacity: 0;
          transition: opacity 0.8s;
        }
      }
      .ctn-qr-code {
        padding: 15px;
        border-radius: 30px;
        background: gray;
        background: linear-gradient(130deg, #ffffff80, #000000);
        svg {
          max-width: 100%;
          max-height: 100%;
          display: block;
          object-fit: contain;
          width: 117px;
          height: 117px;
        }
      }
    }

    .ctn-image-hero {
      margin-top: 5px;
      img {
        max-width: 100%;
        max-height: 100%;
        display: block;
        object-fit: contain;
        width: 290px;
        height: 215px;
      }
    }
    .ctn-name-hero {
    }
    .ctn-desc-hero {
    }
    .ctn-please-join {
      margin-bottom: 10px;
      user-select: none;
    }
    a {
      width: 100%;
      .btn-testflight {
        background: none;
        border: 2px solid #d8d8d8;
        width: 100%;
        text-align: center;
        border-radius: 8px;
        padding: 5px;
        cursor: pointer;
        text-decoration: none;
        color: #fff;
        user-select: none;

        &:active {
          opacity: 0.6;
        }
      }
    }
  }
}

//tablet
@media only screen and (min-width: 768px) {
  .new-mobile-layout {
    .ctn-testflight {
      &.hidden {
      }
      .please-join {
      }

      .apple-logo {
        svg {
        }
      }
    }

    .ctn-card {
      &.gc-mobile {
        align-items: unset;

        > div:nth-child(1) {
          flex: 1;
        }
      }
      padding: 15px 27px;
      .ctn-text-qr {
        .text {
          div {
          }
        }
        .ctn-qr-code {
          padding: 22px;
          svg {
            width: 300px;
            height: 300px;
          }
        }
      }

      .ctn-image-hero {
        img {
        }
      }
      .ctn-name-hero {
      }
      .ctn-desc-hero {
      }
      .ctn-please-join {
      }
      a {
        .btn-testflight {
          &:active {
          }
        }
      }
    }
  }
}

//tablet
@media only screen and (min-width: 768px) and (orientation: landscape) {
  .new-mobile-layout {
    .ctn-card {
      &.gc-mobile {
        > div:nth-child(1) {
          flex: 0 1 auto;
        }
      }
    }
  }
}

.qr-code-mobile {
  width: 172px;
  height: 172px;
}

.gc-mobile .landscape {
  & video {
    height: auto !important;
  }
}

.qrcode-gc-scan-qr {
  display: flex;
  justify-content: space-between;
}

.qr-code-mobile {
  background: gray;
  background: linear-gradient(130deg, #ffffff80, #000000);
  // height: 100%;
  width: 172px;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  canvas {
    width: 150px;
    border-radius: 1rem;
  }
}

@media only screen and (max-width: 767px) {
  .App {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .qrcode-gc-scan-qr {
    flex-direction: column;
  }

  .qr-code-mobile {
    width: 50vw !important;
    height: 50vw !important;
    margin: 1rem auto;
    & canvas {
      width: 42vw;
      height: 42vw;
    }
  }
}
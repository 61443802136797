header {
  height: $height-header;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 27px;

  .ctn-btn-waitlist {
    color: #f0f0f0;

    .btn-waitlist {
      border-radius: 4px;
      padding: 0px;
      border: none;
      background: none;
      cursor: pointer;

      &.join {
        margin-left: 24px;
        font-weight: 600;
        user-select: none;
      }

      &:active {
        opacity: 0.6;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .gc-top {
    display: flex;
    .icons-container {
      display: flex;
      align-items: center;

      .icon {
        width: 22px;
        height: 22px;
        margin: 0 7.5px;
        &.twitter {
          svg {
            margin-left: 8px !important;
          }
        }
        &:nth-child(1) {
          margin-left: 0px;
        }

        svg {
          width: 22px;
          height: 22px;
          margin: 0 7.5px;
          cursor: pointer;

          &.hovered {
            transform: translateY(-8px);
            transition: transform 300ms;
          }
          &.normal {
            transform: translateY(0px);
            transition: transform 300ms;
          }
        }
      }
    }
  }

  .logo {
    cursor: pointer;
    a {
      text-decoration: none;
      color: #fff;
      font-size: 35px;
      user-select: none;
    }
    svg {
      margin-left: 9px;
    }
  }
}

@media only screen and (max-width: 349px) {
  .btn-waitlist {
    font-size: 0.5rem;

    .icon-app {
      width: 80px;
      height: auto;
    }
  }
}

.custom-headers {
  position: fixed;
  width: calc(100% - 108px);
  height: 100px;
}

@media only screen and (max-width: 767px) {
  .custom-headers {
    width: calc(100% - 70px) !important;
    .icon-app {
      width: 130px;
      height: auto;
    }
  }
}

.dynamic-qrcode {
  canvas {
    max-width: 100%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 3840px) {
  .qr-code-page {
    .ctn-heropage {
      .col-left {
        min-width: 1356px;
        margin-right: 240px;
        .image-hero {
          img {
            width: 800px;
            margin: auto;
            height: 894px;
            object-fit: contain;
          }
        }
      }
      .col-right {
        min-width: 1291px;
        margin-left: 240px;
        .ctn-please-join {
          .apple-logo {
            width: 160px;
            svg {
              max-width: unset;
            }
          }
        }
        .ctn-qr-code {
          max-width: unset;
          max-height: unset;
          padding: 120px;
          svg {
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
  .qr-code-page {
    .ctn-heropage {
      .col-left {
        .name-hero {
          svg {
          }
        }
        .image-hero {
          img {
          }
        }
        .desc-hero {
          svg {
          }
        }
      }
      .col-right {
        .ctn-please-join {
          svg {
          }
        }
        .ctn-qr-code {
        }
      }
    }
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .qr-code-page {
    .ctn-heropage {
      .col-left {
        margin-right: 30px;
        min-width: 300px;
        .name-hero {
          svg {
          }
        }
        .image-hero {
          img {
          }
        }
        .desc-hero {
          svg {
          }
        }
      }
      .col-right {
        margin-left: 30px;
        .ctn-please-join {
          svg {
          }
        }
        .ctn-qr-code {
        }
      }
    }
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .qr-code-page {
    .ctn-heropage {
      .col-left {
        margin-right: 30px;
        min-width: 300px;
        .name-hero {
          svg {
          }
        }
        .image-hero {
          img {
          }
        }
        .desc-hero {
          svg {
          }
        }
      }
      .col-right {
        margin-left: 30px;
        .ctn-please-join {
          svg {
          }
        }
        .ctn-qr-code {
        }
      }
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .qr-code-page {
    display: block;
    .ctn-heropage {
      flex-direction: column;
      .col-left {
        margin-right: 0;
        min-width: 321px;
        min-height: 245px;
        .name-hero {
          margin-bottom: 10px;
          svg {
            width: 50%;
            height: 70px;
          }
        }
        .image-hero {
          img {
            width: 40%;
            margin: auto;
            object-fit: contain;
            height: 143px;
            min-width: 321px;
          }
        }
        .desc-hero {
          margin-top: 5px;
          svg {
            width: 60%;
          }
        }
      }
      .col-right {
        margin-left: 0;
        margin-top: 15px;
        .ctn-please-join {
          margin-bottom: 10px;
          svg {
            max-width: 60px;
          }
        }
        .ctn-qr-code {
          padding: 20px;
          width: 50%;
          margin: 0 auto 10px;
        }
      }
    }
  }
}

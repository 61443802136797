body {
  background-color: #000;
  color: #fff;
  margin: 0;
  padding: 0;
  height: -webkit-fill-available;
  width: 100vw;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100vh;
  height: var(--window-height);
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.font-n10 {
  font-size: 10px;
  font-weight: 500;
}

.font-n12 {
  font-size: 12px;
  font-weight: 400;
}

.font-n14 {
  font-size: 14px;
  font-weight: 400;
}

.font-n16 {
  font-size: 16px;
  font-weight: 400;
}

.font-n40 {
  font-size: 40px;
  font-weight: 500;
}

.font-n30 {
  font-size: 30px;
}

.font-n60 {
  font-size: 60px;
  font-weight: 500;
}

.font-n23 {
  font-size: 23px;
}

.font-n23-df {
  font-size: 23px;
}

.font-n50 {
  font-size: 50px;
}

.font-n50-df {
  font-size: 50px;
}

.o-87 {
  opacity: 0.87;
}

.o-8 {
  opacity: 0.8;
}

.o-6 {
  opacity: 0.6;
}

.msr-black {
  font-family: "Montserrat-Black";
}

.msr-bold {
  font-family: "Montserrat-Bold";
}

.msr-extraBold {
  font-family: "Montserrat-ExtraBold";
}

.msr-extraLight {
  font-family: "Montserrat-ExtraLight";
}

.msr-italic {
  font-family: "Montserrat-Italic";
}

.msr-medium {
  font-family: "Montserrat-Medium";
}

.msr-regular {
  font-family: "Montserrat-Regular";
}

.msr-semiBold {
  font-family: "Montserrat-SemiBold";
}

.msr-thin {
  font-family: "Montserrat-Thin";
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .font-n14 {
    font-size: 10px;
    font-weight: 500;
  }
  .font-n23 {
    font-size: 14px;
    font-weight: 500;
  }
  .font-n23-df {
    font-size: 16px;
    font-weight: 450;
  }
  .font-n50 {
    font-size: 35px;
    font-weight: 500;
  }
}

@media only screen and (max-width: 600px) {
  .font-n60 {
    font-size: 30px;
    font-weight: 500;
  }

  .font-n50 {
    font-size: 25px;
    font-weight: 500;
  }

  .font-n30 {
    font-size: 20px;
    font-weight: normal;
  }
  .font-n50-df {
    font-size: 24px;
    font-weight: 600;
  }

  .font-n16 {
    font-size: 11px;
    font-weight: 400;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 3840px) {
  .font-n60 {
    font-size: 160px;
    font-weight: 500;
  }

  .font-n10 {
    font-size: 30px;
  }
  .font-n23 {
    font-size: 69px;
    font-weight: 500;
  }

  .font-n50 {
    font-size: 150px;
    font-weight: 500;
  }
  .font-n30 {
    font-size: 90px;
    font-weight: normal;
  }
}

@font-face {
  font-family: "MontserratExtraBold";
  src: local("MontserratExtraBold"),
    url("../assets/fonts/Montserrat-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratBold";
  src: local("MontserratBold"),
    url("../assets/fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "MontserratMedium";
  src: local("MontserratMedium"),
    url("../assets/fonts/Montserrat-Medium.ttf") format("truetype");
}